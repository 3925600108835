<template>
  <div id="Map">
    <div id="map-container">
      <MglMap
        :accessToken="accessToken"
        :mapStyle.sync="mapStyle"
        container="map-parent"
        @load="onMapLoaded"
        :zoom="zoom"
        :center="center" 
      >
        <MglNavigationControl position="top-right" />
        <MglGeolocateControl position="top-right" />
      </MglMap>
    </div>
    <div class="console-alert frosted-glass" v-if="alert === true">
      <h4>No data to Cluster, please adjust settings.</h4>
      <b-btn @click="clusterAlert">Okay</b-btn>
    </div>
    <div v-if="mobileFlag" class="mobile-bar d-flex justify-content-around align-items-center">
      <div @click="changeToggle(index)" class="mobile-toggles" v-for="(toggle, index) in mobileToggles" :key="index">
        <div class="d-flex justify-content-center align-items-center">
          <mobileIcons :iconType="toggle.iconType" :active="toggle.active" />
        </div>
        <div class="subtitle">{{toggle.name}}</div>
      </div>
    </div>
    <transition name="slide">
      <div id="about-container" class="frosted-glass mobile" v-if="mobileFlag === true && mobileToggles[1].active === true">
        <aboutSection />
      </div>
    </transition>
    <transition name="slide">
      <div id="about-container" class="frosted-glass desktop" v-if="mobileFlag === false && aboutToggle === true">
        <b-btn class="about-close" @click="aboutToggle =false">X</b-btn>
        <aboutSection />
      </div>
    </transition>
    <transition name="slide">
    <div id="sidebar-container" class="text-left frosted-glass" :class="{'mobile': mobileFlag, 'desktop': mobileFlag == false}" v-if="mobileToggles[0].active === true">
      <div class="container p-4">
        <div class="row" v-if="mobileFlag === false">
          <div class="col-12 d-inline-flex">
            <h1>NYC Sidewalk Analysis</h1><small style="color:lightgrey;">beta</small>
          </div>
        </div>
        <div class="row mt-2 d-flex justify-content-around" v-if="mobileFlag === false">
          <b-btn class="block col-4" @click="changeMode('explore')" :class="{'active': mode.explore === true}">Explore Mode</b-btn>
          <b-btn class="block col-4" @click="changeMode('cluster')" :class="{'active': mode.cluster === true}">Cluster Mode</b-btn>
        </div>
        <transition name="slide-fade">
        <div class="explore-container" v-if="mode.explore === true">
          <div class="row mt-2">
            <div class="col-12">
              <p class="description-text mt-2">How much space is enough? Choose a minimum amount of sidewalk area per person. Areas in red have less sidewalk area per person than the selected value.</p>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-8">
              <b-input-group prepend="sidewalk area" append="sqft">
                <b-form-input v-model="filterValue" type="number" min="15" max="99" maxlength="2" ></b-form-input>
              </b-input-group>
            </div>
          </div>
          <hr />
          <div class="row mt-2">
            <div class="col-12">
              <p class="description-text mt-2">Use the sliders below to adjust the percentage of people per category to create your own scenarios, such as for phases of reopening or based on what is open in your own neighborhood.
              </p>
              <p class="description-text">
                1 equals an estimated pre-quarantine sidewalk population, so if half of the retail is open in your scenario, place the slide at 0.5 or if you think there are more residents out than before, place it at 1.25 or 1.5.
              </p>
              <p class="description-text">
                Click on the points on the map to get neighborhood specific data.
              </p>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-10 col-lg-10 col-sm-10 m-0 p-1 mx-auto">
              <div class="slider-parent" v-for="input in sliderObject" :key="input.label+'-key'">
                <div class="standard-text label">{{input.label}}</div>
                <vue-slider :title="'adjust ' + input.label" v-model="input.value" :data="input.data" :ref="input.dataName"  @drag-end="setInputValues" @error="sliderError" :stop-propagation="true" v-bind="sliderOptions" :clickable="false" :absorb="true" :lazy="true" :marks="true">
                </vue-slider>
              </div>
            </div>
          </div>
          <hr>
          <div class="row mt-4 d-flex justify-content-center">
            <b-btn class="block col-4" @click="aboutToggle = true">Open About</b-btn>
          </div>
          <hr>
          <div class="row">
            <div class="col-12">
              <p class="description-text"> 
                This is not a public health analysis nor a safety recommendation. It is an aggregation and visualization of third party data sets that may contribute to sidewalk pedestrian density and is provided “as is.”
              </p>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-12 text-center">
              <div>
                built by<br /> <a href="https://ui.kpf.com" target="_blank"><b-img :src="kpfui_logo" /></a>
              </div>
              <small class="m-2">© 2020 Kohn Pedersen Fox Associates </small>
            </div>
          </div>
        </div>
        </transition>
        <!-- end of explore mode -->
        <transition name="slide-fade">
          <div class="cluster-container" v-if="mode.cluster === true">
            <div class="row mt-2">
              <div class="col-12">
                <p class="description-text mt-2">Each cluster is a collection of areas aggregated together based on shared similarities of characteristics that are causing sidewalk stress.</p>
                <p class="description-text">
                  To cluster a new data set, return to Explore Mode.
                </p>
              </div>
            </div>
            <hr />
            <div class="row mt-4">
              <div class="col-12">
                <h4>{{clusterTitle}}</h4>
                <p class="description-text mt-2">Each point below corresponds to a cluster on the map. Use the chart below to see the characterstics of each cluster.</p>
              </div>
            </div>
            <div class="row mt-2">
              <b-input-group prepend="Select a Graph" class="col-12">
                <b-form-select :disabled="clusteringLayerAdded == false" variant="dark" class="option-select" @change="createGraphData(graphOption)" v-model="graphOption" :options="graphOptions">
                </b-form-select>
              </b-input-group>
            </div>
            <div class="row">
              <div class="col-12" id='chart'>
                 <apexchart type="scatter" height="350" :options="chartOptions" :series="series"></apexchart>
              </div>
            </div>
          </div>
        </transition>
        <!-- end of cluster mode  -->
      </div>
    </div>
    </transition>
    <div class="legend-container" v-show="mode.cluster != true" :class="{'mobile': mobileFlag, 'desktop': mobileFlag==false}">
      <div class="row">
        <div class="col-10 mx-auto mt-2">
          <h4>{{legendTitle}}</h4>
          <small>Square Feet of Sidewalk per Person</small>
        </div>
      </div>
      <div class="row m-2 metric-gradient-parent">
        <div class="metric-gradient col-12 col-md-12 col-sm-12" ref="metric-gradient" :style="{'background': 'linear-gradient(to right,' + colorRamp.toString() +')'}"></div>
      </div>
      <div class="row m-2" id="legend-container">
        <div id="legend-axis" class="col-12 d-flex flex-row justify-content-between">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// IMPORT MAPBOX FUNCTIONS AND TEMPLATES
import Mapbox from 'mapbox-gl'
// vue slider import
import VueSlider from 'vue-slider-component'
// slider template styling
import 'vue-slider-component/theme/default.css'
// mapbox component import
import {
  MglMap,
  MglNavigationControl,
  MglGeolocateControl
} from 'vue-mapbox'

import { mapGetters } from 'vuex'

import { format } from 'd3-format'

import { axisBottom } from 'd3-axis'
import { scaleLinear } from 'd3-scale'
import { select } from 'd3-selection'

var map = null

var thousandFormat = format(",.2r")
var popup = null
var featureID = null
let tooltipData = []
let graphOption = ""

import mobileIcons from "@/components/mobile_icons.vue";
import aboutSection from "@/components/about_section.vue";

export default {
  name: 'Map',
  data () {
    return {
      accessToken:
        'pk.eyJ1Ijoia3BmdWkiLCJhIjoiY2p6MWcxMXl4MDFlbTNsbDc1bnp6N3FjYSJ9.66qFOXwI661MOPOf7x96yA',
      mapStyle: 'mapbox://styles/kpfui/ckpee7ex8063d18pgand9peev',
      zoom: 10,
      center: [-73.96, 40.76],
      clusterTitle: "",
      graphOption: "SWPop_Rpop",
      kpfui_logo: require("@/assets/kpfui_logo.png"),
      xyz_logo: require("@/assets/xyz_logo.png"),
      optionSelected: 'phase 1',
      clusteringLayerAdded: false,
      aboutToggle: false,
      alert: false,
      clusterColors: [
        [0, '#63D8F2'],
        [1, '#D4D253'],
        [2, '#39B655'],
        [3, '#663399'],
        [4, '#D45353'],
        [5, '#A04F9E']
      ],
      mode:{
        explore: true,
        about: false,
        cluster: false
      },
      mobileToggles:[
        {
          name: 'explore',
          iconType: 'tool',
          active: true
        },
        {
          name: 'about',
          iconType: "info",
          active: false
        }
      ],
      sliderOptions:{
        "dotSize": 14,
        "lazy": true,
        "tooltip": "none",
        "railStyle":{
          "background-color": "rgba(253, 203, 204, 0.41)"
        },
        "dotStyle":{
          "background-color": "#F24B6A"
        },
        "processStyle":{
          "background-color": "#ff4c5f"
        }
      },
      series: [{
        name: "",
        data: []
      },{
        name: "",
        data: []
      },{
        name: "",
        data: []
      }],
      chartOptions: {
        toolbar: {
          show: false,
          tools:{
            show: false
          }
        },
        theme: {
          mode: 'dark', 
          palette: 'palette1', 
          monochrome: {
            enabled: false,
            color: '#255aee',
            shadeTo: 'light',
            shadeIntensity: 0.65
          },
        },
        colors: ['#63D8F2', '#D4D253', '#39B655', '#663399', '#D45353' ,'#A04F9E'],
        chart: {
          height: 350,
          type: 'scatter',
          zoom:{
            enabled: false
          }
        },
        dataLabels:{
          style: {
            fontSize: '16px',
          }
        },
        markers:{
          size: 14
        },
        xaxis: {
          enabled: false
        },
        yaxis:{
          max: 500,
          title:{
            text: "avg # of pedestrians on the sidewalk",
            style: {
              fontSize: '14px',
              fontWeight: 300,
              fontFamily: "Roboto Mono, monospace"
            },
          }
        },
        tooltip:{
          style:{
            fontSize: "14px"
          },
          custom: function({seriesIndex, w}) {

            var cluster = tooltipData[seriesIndex];

            var clusterName = (String.fromCharCode(97 + parseInt(seriesIndex))).toUpperCase();

            var keys = Object.keys(cluster);

            var color = w.config.colors[parseInt(seriesIndex)];
            
            var popupString = "<div class='container' style='pointer-events:none;'><div class='row mt-2'><div class='col d-flex justify-content-center'><h3 class='tooltip-header' style='color: "+color+"!important'> Cluster "+ clusterName +"</h3></div></div><div class='row'><div class='col' style='text-align:center'><p>The data below shows how each cluster relates to one another.</p></div></div><hr>"

            keys.forEach((key)=>{

              var bold_class = key === graphOption ? 'bold' : 'normal';

              popupString += "<div class='row mt-2 pl-2 pr-2'><div class='tooltip-row'><h6 class='"+bold_class+"' style='margin-left:2px;'>  "+ cluster[key].label+ "</h6><sup class='description-text'>[ "+cluster[key].avg+" ] </sup></div></div>"
            })

            return popupString += "</div>"
          }
        }
      }
    }
  },
  components: {
    VueSlider,
    MglMap,
    MglNavigationControl,
    MglGeolocateControl,
    mobileIcons,
    aboutSection
  },
  created () {
    // ATTACHES MAP TO INSTANCE
    this.mapbox = Mapbox
  },
  mounted () {
    this.subscribeToStore()
  },
  computed: {
    ...mapGetters({
      'hexData': 'getHexData',
      "sliderObject": "getSliderObject",
      "headerDict": "getHeaderDict",
      "options":"getOptions",
      "maxScore":"getMaxScore",
      "colorRamp": "getColorRamp",
      "colorScale": "getColorScale",
      "mapScale":"getMapScale",
      "clusterData": "getClusterData",
      "clusterScore": "getClusterScore",
      "mobileFlag": "getMobileFlag"
    }),
    legendTitle(){
      return this.mobileFlag === true ? 'NYC Sidewalk Analysis' : 'Legend'
    },
    filterValue:{
      get(){
        return this.mapScale.min
      },
      set(newValue){

        let val = parseInt(newValue);

        const maxValue = 99;

        this.$store.state.mapScale.min = val >= maxValue ? maxValue : val;

        this.createAxis()

        this.hexData.features = this.updateColorValues(this.hexData.features, this.optionSelected, false)

        map.getSource('hex-source-data').setData(this.hexData)
      } 
    },
    graphOptions(){
      return Object.keys(this.headerDict).map((d)=>{
        return {value: d, text: this.headerDict[d].label}
      })
    }
  },
  methods: {
    changeToggle(index){

      let activeToggle = this.mobileToggles[index]

      if(activeToggle.active){
        activeToggle.active = !activeToggle.active 
      }else{
        this.mobileToggles.forEach((d)=> d.active = false)
        this.mobileToggles[index].active = true
      }
    },
    clusterAlert(){
      this.alert = false;

      this.changeMode('explore');
    },
    getClusterData(){
      console.debug('clustering')
      const value = this.filterValue

      let data = []

      this.hexData.features.forEach((d)=>{
        if(d.properties['score'] <= value){
          data.push(d.properties)
        }
      });

      if(data.length > 10){
        this.$store.dispatch('getClusterLabels', {
          "data": data,
          "headers": ["SWPop_Rpop_cv", "SWPop_WPop_cv", "SWPop_Offc_cv", "SWPop_Inst_cv", "SWPop_Retl_cv", "SWPop_Rest_cv", "SWPop_Groc_cv", "SWPop_Bank_cv", "SWPop_ActP_cv"]
        })
      }else{
        this.alert = true
      }
    },
    createAxis(){

      select(".legend-svg").remove()

      var width = 272,
        height = 25;

      this.mapScale.mid = Math.round((this.mapScale.max + this.mapScale.min)/2)

      var data = Object.values(this.mapScale).sort((a, b) => b -a )
      
      // Append SVG 
      var svg = select("#legend-axis")
        .append("svg")
        .attr('class','legend-svg')
        .attr("width", width)
        .attr("height", height);

      // Create scale
      var scale = scaleLinear()
        .domain([data[0], data[2]])
        .range([0, width]);

      //Append group and insert axis
      svg.append("g")
        .call(axisBottom(scale)
        .tickValues(data))
    },
    unsubscribeFromStore(){
      this.hexUnsubscriber()
      this.clusterUnsubscriber()
    },
    createGraphData(col){

      var data = [...Array(parseInt(this.clusterScore[0])).keys()]

      var clusterDict = Object.assign({}, data.map(() => []))

      const property = col === undefined ? 'SWPop_Rpop' : col + ""

      this.clusterTitle = "Average "+this.headerDict[property].label + " by Cluster"

      graphOption = property

      this.clusterData.features.forEach((d)=>{
        clusterDict[d.properties['cluster_label']].push(d.properties[property+"_cv"])
      })
      
      this.series = Object.keys(clusterDict).map((d)=>{

        let average = (array) => array.reduce((a, b) => a + b) / array.length; 

        const avg = thousandFormat((Math.round(average(clusterDict[d]) * 10) / 10))
        clusterDict[d] = avg

        return {
          name: "cluster " + (String.fromCharCode(97 + parseInt(d))).toUpperCase(),
          data:[[parseInt(d), parseInt(avg)]]
        }
      })
    },
    createClusterTooltipData(clusterData){
      var data = [...Array(parseInt(this.clusterScore[0])).keys()]

      var clusterDict = Object.assign({}, data.map(() => {}))

      var keys = this.sliderObject.map((d)=>{ return d.dataName })

      Object.keys(clusterDict).forEach((d)=>{
        clusterDict[d] = {}
        keys.forEach((j)=>{ 
          clusterDict[d][j] = []
        })
      })

      let average = (array) => array.reduce((a, b) => a + b) / array.length; 

      keys.forEach((key)=>{
        clusterData.features.forEach((d)=>{
          clusterDict[d.properties['cluster_label']][key].push(d.properties[key])
        })
      })

      Object.keys(clusterDict).forEach((cluster)=>{
        keys.forEach((key)=>{
          let array = clusterDict[cluster][key]

          const avg = thousandFormat((Math.round(average(array) * 10) / 10))

          clusterDict[cluster][key] = {
            avg: avg,
            label: this.headerDict[key].label
          }
        });
      });

      keys.forEach((key)=>{
        let avgs = [];

        Object.keys(clusterDict).forEach((cluster)=>{
          avgs.push(+clusterDict[cluster][key].avg)
        })

        const row_average = average(avgs);

        Object.keys(clusterDict).forEach((cluster)=>{
          clusterDict[cluster][key].avg = clusterDict[cluster][key].avg < row_average ? 'low' : 
          clusterDict[cluster][key].avg > row_average ? 'high': 'mid';
        })
      })

      tooltipData = clusterDict
    },
    subscribeToStore(){

      console.debug('subscribing')

      this.hexUnsubscriber = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'setHexData':
            this.addHexDataToMap(this.hexData)
            break
        }
      })

      this.clusterUnsubscriber = this.$store.subscribe((mutation) =>{
        switch (mutation.type){
          case "setClusterData":

            map.getSource('cluster-source-data').setData(this.clusterData)

            this.createGraphData()
            this.createClusterTooltipData(this.clusterData)

            if(this.clusteringLayerAdded === false){
              this.clusteringLayerAdded = true
              this.addClusterDataToMap(this.clusterData)
            }

            break
        }
      })
    },
    addClusterSource(){
      map.addSource('cluster-source-data', {
        type: 'geojson',
        data: {
          'type': 'FeatureCollection',
          'features': [{
            'type': 'Feature',
            'properties': { 'name': 'Null Island' },
            'geometry': {
              'type': 'Point',
              'coordinates': [ 0, 0 ]
            }
          }]
        },
        generateId: true
      })
    },
    addHexDataSource () {
      map.addSource('hex-source-data', {
        type: 'geojson',
        data: {
          'type': 'FeatureCollection',
          'features': [{
            'type': 'Feature',
            'properties': { 'name': 'Null Island' },
            'geometry': {
              'type': 'Point',
              'coordinates': [ 0, 0 ]
            }
          }]
        },
        generateId: true
      })
    },
    // FIRES ON MAP LOAD
    onMapLoaded (event) {
      console.debug('map is loaded')

      map = event.map

      this.addHexDataSource()
      this.addClusterSource()

      this.$store.dispatch('readHexData', []);
    },
    updateColorValues(data, option, updateSliders){

      function arrayToObject(array, keyField) {
        return array.reduce((obj, item) => {
          obj[item[keyField]] = item
          return obj
        }, {})
      }

      let sliderObject = arrayToObject(this.sliderObject, 'dataName')

      let headers = Object.keys(sliderObject);

      let headerObject = null
      let value = null

      if(option != undefined){
        headerObject = this.headerDict
        value = option
        
        if (updateSliders){
          this.setInputSliders(headerObject, option)
        }
      }else{
        headerObject = sliderObject
        value = 'value'
      }

      let colorScale = this.colorScale
      colorScale.domain([this.mapScale.min, this.mapScale.max])

      data.forEach((d)=>{

        const prop = d.properties

        let score = 0

        // multiplies the value by the constant from the sliders
        headers.forEach((head)=>{

          score += (prop[head] * headerObject[head][value])
          prop[head+"_cv"] = prop[head] * headerObject[head][value]
        })

        if(prop['SWArea'] > 0 && score > 0 ){
          prop['raw_score'] = score
          prop['score'] = prop['SWArea'] / score
        }else{
          prop['raw_score'] = score
          prop['score'] = 99999
        }
      })

      data.forEach((d)=>{d.properties['color'] = colorScale(d.properties.score)})

      return data
    },
    setInputSliders(headerDict, option){

      let refs = this.$refs

      Object.keys(headerDict).forEach((header)=>{
        refs[header][0].setValue(headerDict[header][option])
      })
    },
    changeMode(mode){
      Object.keys(this.mode).forEach((d)=>{ this.mode[d] = false})

      this.mode[mode] = true

      if(mode === 'cluster'){
        map.setLayoutProperty('hex-bins', 'visibility', 'none');

        this.graphOption = "SWPop_Rpop";

        if(map.getLayer('cluster-bins')){
          map.setLayoutProperty('cluster-bins', 'visibility', 'visible')
        }

        this.getClusterData()
        
      }else if(mode === 'explore'){

        map.setLayoutProperty('hex-bins', 'visibility', 'visible');
        map.setLayoutProperty('cluster-bins', 'visibility', 'none');
        this.createAxis()
      }
    },
    addClusterDataToMap(){

      var colors = this.clusterColors

      map.addLayer({
        id: 'cluster-bins',
        source: 'cluster-source-data',
        type: 'fill',
        paint: {
          "fill-color":{
            property: "cluster_label",
            stops:colors
          },
          'fill-opacity': 0.75,
        }
      })

      this.createMapTooltip('cluster-bins')
    },
    addHexDataToMap (hexData) {
      // try and get the source
      // if source does not exist
      if (map.isSourceLoaded('hex-source-data')) {

        console.debug('loaded')

        map.getSource('hex-source-data').setData(hexData)

      }

      map.addLayer({
        id: 'hex-bins',
        source: 'hex-source-data',
        type: 'fill',
        paint: {
          "fill-color":['get', 'color'],
          'fill-opacity': 0.75,
        }
      })
      console.debug('adding layer')

      this.createMapTooltip('hex-bins')
      this.createAxis()

    },
    setInputValues(inputs, option){

      this.hexData.features = this.updateColorValues(this.hexData.features, option, true)

      map.getSource('hex-source-data').setData(this.hexData)

    },
    sliderError(error){
      console.error(error)
    },
    createMapTooltip (mapLayer) {

      popup = new (this).mapbox.Popup({ closeButton: true, closeOnClick: true })

      map.on('click', mapLayer, (e) => {

        e.originalEvent.cancelBubble = true

        if (document.querySelector('.mapboxgl-popup') === null) {

          
          var prop = e.features[0]['properties']
          popup.setLngLat([e['lngLat']['lng'], e['lngLat']['lat']])


          this.$store.dispatch('readStreetAddressFromLatLng', e).then(response => {
            console.log("createMapTooltip -> response", response)
            
            makePopup(response);
          }, error => {
            console.warn("Got no response from geocoding server.", error)
            makePopup(" ");
          });


          const makePopup = (( location ) => {

            if(mapLayer === 'cluster-bins'){
            
              var cluster = tooltipData[prop['cluster_label']]

              var clusterName = (String.fromCharCode(97 + parseInt(prop['cluster_label']))).toUpperCase()
              var keys = Object.keys(cluster)
              var color = this.chartOptions.colors[parseInt(prop['cluster_label'])]

              var popupString = `<div class='container' style='pointer-events:none;'><div class='row mt-2'><div class='col text-center'><h3>${location}</h3></div></div><div class='row mt-2'><div class='col d-flex justify-content-center'><h3 class='tooltip-header' style='color: ${color}!important'> Cluster ${clusterName}</h3></div></div><div class='row'><div class='col' style='text-align:center'><p>The data below shows how each cluster relates to one another.</p></div></div><hr>`

              keys.forEach((key)=>{
                popupString += `<div class='row mt-2'><div class='col tooltip-row'><h6 style='margin-left:2px;'> ${cluster[key].label}</h6><sup class='description-text'>[ ${cluster[key].avg} ] </sup></div></div>`
              })

              popupString += "</div>"

              popup.setHTML(popupString).addTo(map)
              
            }else{
                popup.setHTML(`<div class='container' style='pointer-events:none;'><div class='row mt-2'><div class='col'><h3>${location}</h3></div></div><div class='row mt-2'><div class='col tooltip-column'><h6>Sidewalk Area: </h6><h5> ${thousandFormat(prop['SWArea'])} sqft</h5></div></div><div class='row mt-2'><div class='col tooltip-column'><h6>Area per Person: </h6><h5> ${(Math.round(prop['score'] * 10) / 10)} sqft</h5></div></div><div class='row mt-2'><div class='col tooltip-column'><h6>Sidewalk Population: </h6><h5> ${thousandFormat((Math.round(prop['raw_score'] * 10) / 10))} </h5></div></div></div>`)
                .addTo(map)
            }
          })
        }
      })

      map.on('mousemove', mapLayer, (e) => {
        map.getCanvas().style.cursor = 'pointer'

        featureID = e.features[0].id

        if (e.features.length > 0) {
          if (featureID !== null) {
            map.setFeatureState(
              { source: 'hex-source-data', id: featureID },
              { hover: false }
            )
          }

          map.setFeatureState(
            { source: 'hex-source-data', id: featureID },
            { hover: true }
          )
        }
      })

      map.on('mouseleave', mapLayer, () => {
        map.getCanvas().style.cursor = ''

        if (featureID !== null) {
          map.setFeatureState(
            { source: 'hex-source-data', id: featureID },
            { hover: false }
          )
        }

        featureID = null
      })
    }
  }
}
</script>

<style lang="scss">
.console-alert{
  position: absolute;
  width: 100vw;
  height: 100vh;

  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  z-index: 20;
}
.apexcharts-tooltip.apexcharts-theme-dark{
  background: rgba(18,18,18,0.99)
}
.tooltip-header{
  font-weight: 700;
}
.tooltip-row{
  display: flex;
  justify-content: flex-start;
}
.bold{
  font-weight: 700!important;
}
.normal{
  font-weight: 400!important;
}
svg{
  background: transparent!important;
}
.mobile-bar{
  position: absolute;
  bottom: 0px;
  width: 100%;
  background: $black;

  padding: 1rem;

  z-index: 10;

  border-top: 1px solid $dark-grey;

  pointer-events: none;
}
// Chart options
#chart{
  margin: 1rem 0;
}
.apexcharts-toolbar{
  display: none!important;
}
.mapboxgl-ctrl-bottom-right{
  display: none;
}
.apexcharts-canvas.apexcharts-theme-dark{
  background: transparent!important;
}
.apexcharts-legend-text{
  font-size: 16px!important;
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
/** vue slide up/down transition */
.slide-enter-active {
   transition-duration: 0.4s;
   transition-timing-function: ease-in;
}

.slide-leave-active {
   transition-duration: 0.3s;
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
   max-height: 100vh;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
/* end of vue transition*/
img{
  height: 25px;
  width: auto;
}

// SLIDER OPTIONS
.vue-slider-mark{
  background-color:#fdcbcc;
  border-radius: 50%;
  font-family: 'Roboto Mono', monospace;
  opacity:0.5;
  line-height: 1.33;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.45px;
}
.vue-slider-mark-label{
  font-family: 'Roboto Mono', monospace;
}

.slider-parent{
  margin: 0 0 3rem 0;
}
// MAP OPTIONS
#Map {
  width: 100vw;
  height: 100vh;
  overflow:hidden;
}
.mapboxgl-canvas {
  left: 0;
}
#map-container {
  position: absolute;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;

  overflow: hidden;
}

.legend-container{
  position: absolute;

  width: 350px;

  background: $black;

  

  z-index: 4;

  .metric-gradient-parent{
    padding: 0 15px;
  }
  .metric-gradient{
    border-radius: 5px;
    height: 15px;
  }

  
  &.mobile{
  }
  &.desktop{
    bottom: 25px;
    right: 25px;
    padding: 1rem;
  }
}
.mapboxgl-popup-content{
  // background: #181818;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  background-color: rgba(13,13,13,0.8);
}
.mapboxgl-popup-close-button{
  color: #ffffff;
}
.mapboxgl-ctrl-bottom-left{
  display: none;
}
// ABOUT
.about-close{
  position: absolute;
  right: 1rem;
  top: 1rem;
}
#about-container{
  position: absolute;
  left: 0;

  background: $black;

  overflow-y: auto;

  top: 0px;
  width: 100%;

  &.mobile{
    height: calc(100vh - 80px);
  }
  &.desktop{
    height: 100vh;
  }
  

  z-index: 5;
}
// SIDEBAR 
#sidebar-container{
  position: absolute;
  left: 0;

  // background: $black;
  background: transparent;
  overflow-y: auto;

  &.mobile{
    bottom: 80px;
    width: 100%;
    height: calc(50vh - 80px);
  }
  &.desktop{
    top: 0px;
    width: 500px;
    height: 100vh;
  }
}
.mobile-toggle{
  position: relative;
  margin: auto;

}
::-webkit-scrollbar {
  width: 6px;
} 
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
} 
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
}
.map-marker {
  background-image: url("https://avatars2.githubusercontent.com/u/600935?s=400&v=4");
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}
</style>
