import { render, staticRenderFns } from "./assumptions_table.vue?vue&type=template&id=7c508192&"
var script = {}
import style0 from "./assumptions_table.vue?vue&type=style&index=0&id=7c508192&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports