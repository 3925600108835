<template>
  <div id="about-section" class="container">
    <div class="row mt-4">
      <div class="col-10 mx-auto">
       <div class="row">
         <div class="col-12">
           <img class="w-100 h-auto" width="100%" height="auto" :src="mapScreenshot" alt="">
         </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <h2>Disclaimer</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p>
              This is not a public health analysis nor a safety recommendation. It is an aggregation and visualization of third party data sets that may contribute to sidewalk pedestrian density and is provided “as is.”
            </p>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12">
            <h4>Data Sources</h4>
            <ul>
              <li v-for="(source,index) in dataSourcesAlpha" :key="index">
                <a :href="source.link" target="_blank">{{source.name}}</a>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-12">
            <h3>Methodology</h3>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <h4>Office & Institutional</h4>
            <p>
              Only office and institutional buildings with Floor-Area Rations greater than 4 were included in the analysis with the assumption these building would likely have elevators and thus pedestrians queuing outside. 
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h4>
              Hex Grid Spatial Joins
            </h4>
            <p>
              All spatial data used in this study was joined to a grid of regular hexagons 1000’ across. Resident and job population geometry were joined to grid in proportion to the area of that polygon in the grid cell in question. Eg. A census block with 200 jobs that’s 40% in grid cell A and 60% in grid cell B, will be joined as 80 and 120 jobs to A and B, respectively. Point data was joined to the hex grid based on location. Sidewalk geometry was split by hex grid cells, areas for the split sidewalk polygons were calculated, and these areas were then joined to the hex grid, summing all constituent sidewalk areas per hex cell together. 
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h4>Exclusion of Certain Data Points</h4>
            <p>
               Grid cells were omitted if more that 50% of that cell was ocean or waterway. Grid cells were omitted if the cumulative sidewalk ware in that cell was less that 1000 sf. Both types of cells were the overwhelming cause of outliers due to low sidewalk area.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h4>Sidewalk Population</h4>
            <p>
              Because this studied focused on pedestrian density, deriving a sidewalk population from the various datasets was a critical component of the analysis. Given that datapoints for the datasets included represent either a person, business (containing multiple people), or building (possibly containing multiple businesses and many people) we needed to first calculate a “total population” per data point.  Once the total population was calculated we took a certain percentage as the pre-covid pedestrian population per data point at a snapshot in time during peak hours. (see table for more details). The model served as a feedback loop in which these figures were adjusted based on the resulting sf/pedestrian figure that model produced. 
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <assumptionTable />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <h4>Weighting</h4>
            <p>
              The composite sidewalk population figures produced by the model represent a pre-covid estimate. In order to simulate varying levels of workplace inhabitation and business-related activity in a dynamic way, these population values can be weighted (scale 0-1.25). The specified weight is applied to the pre-covid sidewalk population as a scalar multiplier. 
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h4>
              Composite Scores
            </h4>
            <p>
              Composite scores represent the average amount of sidewalk area (in square feet) per pedestrian in a hex-grid cell at a snapshot in time. It is calculated as the quotient of sidewalk area and the sum of weighted composite scores.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h4>
              Clustering
            </h4>
            <p>
              K-means clustering is applied to the hex bins that are less than or equal to square-foot of sidewalk area per person set by the user on a range of 0 – 100. All data which is within the range are ran through an optimization algorithm to determine the best number of clusters from 2 – 8 based on the distribution of data in all dimensions. Where the number of clusters equates to the Sum-of-squared error (SSE) from the resultant clusters. The SSE is determined by the distance of each point from the assigned cluster centroid in Euclidean distance within the dimensional space of the data. The algorithm optimizes for the number of clusters required to achieve the highest information gain from the lowest SSE. If no optimal number of clusters is found, then the algorithm returns 3 clusters. Once the number of clusters is returned, the algorithm runs a K-means clustering using the recommended number clusters and searches the data for a stable clustering condition. Once a stable condition is reached and the cluster centroids find convergence, the data is returned to the front-end visualization.
            </p>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12 text-left">
            <a href="https://ui.kpf.com" target="_blank"><b-img :src="kpfui_logo" /></a>
            <div style="margin-bottom: 1rem;"></div>
            <p>
              This tool is developed by <a href="https://ui.kpf.com">KPF Urban Interface</a>, a research and design wing within <a href="https://www.kpf.com">Kohn Pedersen Fox</a> Architects, that engages with the contemporary issues that impact the future of our buildings and cities. KPFui uses spatial and temporal urban data analytics for informed decision making in the design of buildings and cities for people while engaging with some of the most critical contemporary urban topics: livability, sustainability, and mobility.
            </p>
            <p>
              We believe that smart cities & buildings are not about drones, sensors or autonomous vehicles. A truly smart city should leverage data to design better neighborhoods and utilize technology to make that data transparent and publicly accessible. We think people should be in control of technology and not the other way around.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-left">
            <a href="https://livexyz.com/" target="_blank"><b-img :src="xyz_logo" style="height: 40px;" /> </a>
            <div style="margin-bottom: 1rem;"></div>
            <p>
              <a href="https://livexyz.com/" target="_blank">Live XYZ</a> is a New York City based startup driven by a mission to put every storefront on the map. We deliver precise, complete storefront and point-of-interest data and technology. Using our proprietary surveying and mapping methodology, Live XYZ has built a hyper-accurate map of places and how they change over time. Since 2014, our on-the-ground mapping team has been walking the streets of New York, mapping, photographing and classifying every point-of-interest on every block with the Live XYZ mobile app. The result is a ground-truthed and cloud-verified first-party dataset of storefront information, trusted by city governments, community organizations, and real estate professionals.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <div>
              built by<br /> <a href="https://ui.kpf.com" target="_blank"><b-img :src="kpfui_logo" /></a>
            </div>
            <small class="m-2">© 2020 Kohn Pedersen Fox Associates</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import assumptionTable from "@/components/assumptions_table.vue";

export default {
  name: "about-section",
  components:{
    assumptionTable
  },
  data(){
    return {
      kpfui_logo: require("@/assets/kpfui_logo.png"),
      xyz_logo: require("@/assets/xyz_logo.png"),
      mapScreenshot: require("@/assets/map_screenshot.png"),
      dataSources: [
        {name: "Resident Population - US Census (2018)", link:" https://www.census.gov/"},
        {name: "Job Population - LEHD Survey, US Census (2017)", link: "https://lehd.ces.census.gov/data/"},
        {name: "Office Buildings - NYC Pluto", link:"https://www1.nyc.gov/site/planning/data-maps/open-data/dwn-pluto-mappluto.page"},
        {name: "Institutional Buildings - NYC Pluto", link: "https://www1.nyc.gov/site/planning/data-maps/open-data/dwn-pluto-mappluto.page"},
        {name:"Business Location Data - LiveXYZ access June 2020", link:"https://livexyz.com/" },
        {name:"Buildings Permits - NYC Dept. of Buildings", link:"https://opendata.cityofnewyork.us/"},
        {name:"NYC administrative boundaries - NYC Open Data", link: "https://opendata.cityofnewyork.us/"}
      ]
    }
  },
  computed:{
    dataSourcesAlpha(){
      let d = this.dataSources
      return d.sort((a,b)=>{
        const ta = a.name.toUpperCase(),
          tb = b.name.toUpperCase();

        return (ta < tb) ? -1 : (ta > tb) ? 1 : 0; 
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#about-section{
  p{
    text-align: justify;
  }
}
</style>