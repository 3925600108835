import Vue from 'vue'
import Vuex from 'vuex'
import { json } from 'd3-fetch'
import { interpolateTurbo, interpolateSpectral} from 'd3-scale-chromatic'
import { scaleSequential, } from 'd3-scale';
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    hexData: [],
    sliderObject:[],
    headerDict: [],
    options: [],
    colorRamp: [],
    colorScale: scaleSequential(interpolateTurbo).domain([0, 1]),
    maxScore: 0,
    mapScale:{
      min: 0,
      max: 0,
      mid: 0
    },
    clusterData: [],
    clusterScore: [],
    mobileFlag: false
  },
  getters: {
    getHexData (state) {
      return state.hexData
    },
    getSliderObject (state) {
      return state.sliderObject
    },
    getHeaderDict (state) {
      return state.headerDict
    },
    getOptions( state ){
      return state.options
    },
    getMaxScore(state){
      return state.maxScore
    },
    getColorRamp(state){
      return state.colorRamp
    },
    getColorScale(state){
      return state.colorScale
    },
    getMapScale(state){
      return state.mapScale
    },
    getClusterData(state){
      return state.clusterData
    },
    getClusterScore(state){
      return state.clusterScore
    },
    getMobileFlag(state){
      return state.mobileFlag
    }
  },
  mutations: {
    setHexData (state, newHexData) {
      state.hexData = newHexData
    },
    setSliderObject (state, newSliderObject){
      state.sliderObject = newSliderObject
    },
    setHeaderDict (state, newHeaderDict){
      state.headerDict = newHeaderDict
    },
    setOptions( state, newOptions){
      state.options = newOptions
    },
    setMaxScore( state, newMaxScore){
      state.maxScore = newMaxScore
    },
    setColorRamp(state, newColorRamp){
      state.colorRamp = newColorRamp
    },
    setColorScale( state, newColorScale){
      state.colorScale = newColorScale
    },
    setMapScale( state, newMapScale){
      state.mapScale = newMapScale
    },
    setClusterData( state, newClusterData){
      state.clusterData = newClusterData
    },
    setClusterScore( state, newClusterScore){
      state.clusterScore = newClusterScore
    },
    setMobileFlag(state, newMobileFlag){
      state.mobileFlag = newMobileFlag
    }
  },
  actions: {
    getClusterLabels(context, payload){
      
      // console.time("clustering_time")

      axios.post('https://megatron.kpfui.dev/getModelClusterLabels', {
        data: payload.data,
        headers: payload.headers
      })
      .then(function (response) {

        function arrayToObject(array, keyField) {
          return array.reduce((obj, item) => {
            obj[item[keyField]] = item
            return obj
          }, {})
        }
        
        const cd = arrayToObject(JSON.parse(response['data']['data']), 'HexID')

        let cluster_data_features = []
        const keys = Object.keys(cd).map(x => +x)

        context.state.hexData.features.forEach((d)=>{
  
          if(keys.includes(d.properties['HexID'])){
            d.properties['cluster_label'] = cd[d.properties['HexID']]['cluster_label']
            cluster_data_features.push(d)
          }
        })

        const cluster_data = {
          'type': 'FeatureCollection',
          'features': cluster_data_features
        }

        context.commit('setClusterScore', response['data']['score'])

        context.commit('setClusterData', Object.assign(cluster_data, { _isVue: true }))
        // console.timeEnd("clustering_time");
      })
      .catch(function (error) {
        console.error(error);
      });
    },
    readStreetAddressFromLatLng(context, payload){
      const lat = payload.lngLat.lat,
        lng = payload.lngLat.lng;

    
      return new Promise((resolve, reject) => {
        // Do something here... lets say, a http call using vue-resource
        axios.get("https://api.mapbox.com/geocoding/v5/mapbox.places/"+lng+","+lat+".json?types=address&access_token=pk.eyJ1Ijoia3BmdWkiLCJhIjoiY2p6MWcxMXl4MDFlbTNsbDc1bnp6N3FjYSJ9.66qFOXwI661MOPOf7x96yA").then((response)=>{
            // http success, call the mutator and change something in state
            resolve(response.data.features[0].text);  // Let the calling function know that http is done. You may send some data back
        }, error => {
            // http failed, let the calling function know that action did not work out
            reject(error);
        })
      })
    },
    readHexData(context){

      json('./Hex_PeakPop_0702.json', function(){
      }).then(function(response){

        const headerDict = {
          "SWPop_Rpop": {
            "phase 2":1.25,
            "phase 1": 1,
            weekday: 0.5,
            weekend: 1,
            label: "Residents on the Sidewalk",
          },
          "SWPop_WPop": {
            "phase 2": 0.25,
            "phase 1": 0.25,
            weekday: 1,
            weekend: 0.5,
            label: "Workers on the Sidewalk",
          },
          "SWPop_Offc": {
            "phase 2": 0.25,
            "phase 1": 0.25,
            weekday: 1,
            weekend: 0.25,
            label: "Office Workers Queuing for Lobbies",
          },
          "SWPop_Inst": {
            "phase 2": 0.25,
            "phase 1": 0.25,
            weekday: 1,
            weekend: 0.25,
            label: "Institutional Workers Queuing for Lobbies",
          },
          "SWPop_Retl": {
            "phase 2": 0.75,
            "phase 1": 0.5,
            weekday: 0.25,
            weekend: 1,
            label: "Retail Patrons",
          },
          "SWPop_Rest": {
            "phase 2": 1.25,
            "phase 1": 0.5,
            weekday: 0.25,
            weekend: 1,
            label: "Restaurant Patrons",
          },
          "SWPop_Groc": {
            "phase 2": 1,
            "phase 1": 1,
            weekday: 0.25,
            weekend: 1,
            label: "Grocery Store Patrons",
          },
          "SWPop_Bank": {
            "phase 2": 0.75,
            "phase 1": 0.5,
            weekday: 0.25,
            weekend: 0.5,
            label: "Bank Patrons",
          },
          "SWPop_ActP": {
            "phase 2": 0.25,
            "phase 1": 1,
            weekday: 1,
            weekend: 0.5,
            label: "Construction Workers Queuing for Site Entry",
          }
        }

        const headers = Object.keys(headerDict)

        let options = Object.keys(headerDict[headers[0]]).filter((d)=>{return d != 'label'})

        options = options.map((d)=>{
          return {
            value: d,
            text: d
          }
        })

        const defaultSet = 'phase 2'

        let sliderObject = headers.map((d)=>{
          return {
            dataName: d,
            label: headerDict[d].label,
            marks: [],
            data: [0,0.25,0.5,0.75,1, 1.25, 1.5], 
            value: headerDict[d][defaultSet],
          }
        })

        response.features.forEach((d)=>{

          let score = 0

          let prop = d.properties

          headers.forEach((head)=>{
            score +=  (prop[head] * headerDict[head][defaultSet]);
            prop[head+"_cv"] = (prop[head] * headerDict[head][defaultSet]);
          })

          if(prop['SWArea'] > 0 && score > 0 ){
            prop['raw_score'] = score
            prop['score'] = prop['SWArea'] / score
          }else{
            prop['raw_score'] = score
            prop['score'] = 9999
          }

        })
        // OG color Ramp
        // var colorRamp = ['#000000', "#0D014D" ,"#4C0884", "#71077E","#FF0004"]

        // var colorScale = scaleLinear()
        //   .domain([350, 5])
        //   .range(colorRamp).clamp(true)
        const mapScale = {
          max: 100,
          min: 30,
        }

        mapScale.mid = Math.round((mapScale.max + mapScale.min)/2)

        var colorScale = scaleSequential(interpolateSpectral).domain([mapScale.min, mapScale.max]).unknown("#000000")
        // var opacityScale = scaleLinear([0, 75]).range([0, 1])

        response.features.forEach((d)=>{
          d.properties['color'] = colorScale(d.properties.score) 
          // d.properties['opacity'] = opacityScale(d.properties['raw_score'])
        })

        let colorRamp = []
        // interpolateTurbo
        for (let i = 0; i < 1; i+=0.1) {
          colorRamp.push(interpolateSpectral(i))
        }

        context.commit("setColorRamp", colorRamp.reverse())
        context.commit('setMapScale', mapScale)

        context.commit('setColorScale', colorScale)

        context.commit('setHeaderDict', Object.assign( headerDict ), { _isVue: true })

        context.commit('setSliderObject', Object.assign( sliderObject ), { _isVue: true })

        context.commit('setHexData', Object.assign( response ), { _isVue: true } )

        context.commit('setOptions', options)
      })
    }
  }
})
